import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import axios from 'axios';
import SimpleHeader from "../../components/Headers/SimpleHeader";
import { Card, Container } from "reactstrap";
import Typography from "@mui/material/Typography";

function Row(props) {
    const { records, date, empName } = props;
    const [open, setOpen] = useState(false);


    return (
        <React.Fragment>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                <TableCell>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell colSpan={6}>
                    Employee: {empName} | Date: {date}
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box margin={1}>
                            <Table size="small" aria-label="purchases">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Machine Job</TableCell>
                                        <TableCell align="right">Start Time</TableCell>
                                        <TableCell align="right">Stop Time</TableCell>
                                        <TableCell align="right">Machine Start Time</TableCell>
                                        <TableCell align="right">Machine Stop Time</TableCell>
                                        <TableCell align="right">Machine Type</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {records.map((row, index) => (
                                        <TableRow key={index}>
                                            <TableCell>{row.m_job || 'N/A'}</TableCell>
                                            <TableCell>{row.start_time || 'N/A'}</TableCell>
                                            <TableCell>{row.stop_time || 'N/A'}</TableCell>

                                            <TableCell>{row.m_start_time || 'N/A'}</TableCell>
                                            <TableCell>{row.m_stop_time || 'N/A'}</TableCell>
                                            <TableCell>{row.m_type || 'N/A'}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}

Row.propTypes = {
    records: PropTypes.array.isRequired,
    date: PropTypes.string.isRequired,
    empName: PropTypes.string.isRequired,
};

export default function CollapsibleTable() {
    const [groupedData, setGroupedData] = useState([]);

    useEffect(() => {
        const fetchTimeCardData = async () => {
            try {
                const token = localStorage.getItem('token');
                const response = await axios.get('https://reports.schuepferinc.com/dbapp/combined_time_cards', {
                    headers: {
                        'x-access-token': token,
                    },
                });

                const processedData = response.data.combined_time_cards.map((group) => ({
                    records: group.records || [],
                    date: group.records.length > 0 ? new Date(group.records[0].created_date).toLocaleDateString() : 'N/A',
                    empName: group.records.length > 0 ? group.records[0].emp_name : 'N/A',
                }));

                setGroupedData(processedData);
            } catch (error) {
                console.error('Error fetching time card data:', error);
            }
        };

        fetchTimeCardData();
    }, []);

    return (
        <>
            <SimpleHeader name="React Tables" parentName="Tables" />
            <Container className="mt--6" fluid>
                {/*<AppBar position="static">*/}
                {/*    <Toolbar />*/}
                {/*</AppBar>*/}
                <Card>
                    <TableContainer component={Paper}>
                        <Table aria-label="collapsible table">
                            <TableHead>
                                <TableRow>
                                    <TableCell />
                                    <TableCell>
                                    <Typography
                                        variant="subtitle1"
                                        style={{ fontWeight: 'bold', fontSize: '18px' }}
                                    >
                                        Date and Employee Name
                                    </Typography>
                                    </TableCell>
                                    </TableRow>
                            </TableHead>
                            <TableBody>
                                {groupedData.map((groupData, index) => (
                                    <Row
                                        key={index}
                                        records={groupData.records}
                                        date={groupData.date}
                                        empName={groupData.empName}
                                    />
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Card>
            </Container>
        </>
    );
}

