import React from "react";
import { getJwt } from "../helpers/jwt";
import axios from "axios";
import { withRouter } from "react-router-dom";
// import Sact from '../../layouts/Sact'
class AuthenticatedComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            user: undefined,
        };
    }

    componentDidMount() {
        const jwt = getJwt();
        if (!jwt) {
            this.props.history.push("/auth/login");
        }
        axios
            .get("https://reports.schuepferinc.com/dbapp/user/token_details", { headers: { "x-access-token": jwt } })
            .then((res) => this.setState({ user: res.data }))

            .catch((err) => {

                localStorage.removeItem("token");
                this.props.history.push("/auth/login");
            });

    }

    render() {
        if (this.state.user === undefined) {
            return (
                <div>
                    <h1>Loading...</h1>
                </div>
            );
        }

        return <div>{this.props.children}</div>;
    }
}
export default withRouter(AuthenticatedComponent);
