/*!

=========================================================
* Argon Dashboard React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// import Index from "views/Index.js";
import Daily_sheet from "./views/Action/Daily_sheet.js";
import Login from "./views/Action/Login.js";
import Tables from "./views/Action/Tables.js";
import Time_card from "./views/Action/Time_card";
import Images_page from "./views/Action/Images"
import Icons from "./views/Action/Icons"

import Profile from "./views/Action/Profile"


const routes = [


  {
    path: "/images",
    name: "Images",
    icon: "ni ni-image text-orange",
    component: Images_page,
    layout: "/admin",
  },
  {
    path: "/daily-sheet",
    name: "Daily Sheet",
    icon: "ni ni-single-copy-04 text-blue",
    component: Daily_sheet,
    layout: "/admin",
  },
  {
    path: "/time-card",
    name: "Time Card",
    icon: "ni ni-watch-time text-blue",
    component: Time_card,
    layout: "/admin",
  },
  {
    path: "/tables",
    name: "Daily Sheet Tables",
    icon: "ni ni-bullet-list-67 text-red",
    component: Tables,
    layout: "/admin",
  },
  {
    path: "/icons",
    name: "Time Card Table",
    icon: "ni ni-time-alarm text-red",
    component: Icons,
    layout: "/admin",
  },
  {
    path: "/login",
    name: "Login",
    icon: "ni ni-key-25 text-info",
    component: Login,
    layout: "/auth",
    invisible: true,
  },
  {
    path: "/Profile",
    name: "Site Images Table",
    icon: "ni ni-tv-2 text-primary",
    component: Profile,
    layout: "/admin",
  },
  // {
  //   path: "/register",
  //   name: "Register",
  //   icon: "ni ni-circle-08 text-pink",
  //   component: Register,
  //   layout: "/auth",
  // },
];
export default routes;
