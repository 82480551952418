import React, {useState, useRef, useEffect} from 'react';
import axios from 'axios';
import './app.css';
import Images_header from '../../components/Headers/Images_header';
import { Card, CardBody, CardHeader, Col, Container, Form, Input, Row, Progress, Button } from 'reactstrap';
import data1 from './data.txt'; // Import the local JSON file
const ImageUpload = () => {
    const [selectedFile, setSelectedFile] = useState(null);
    const [selectedJobId, setSelectedJobId] = useState(''); // State variable for job name ID
    const [uploadProgress, setUploadProgress] = useState(0);
    const [uploadCompleted, setUploadCompleted] = useState(false);
    const [uploadError, setUploadError] = useState(false); // State variable for upload error
    const [imageName, setImageName] = useState('');
    // const [data, setData] = useState([]);
    const [options, setOptions] = useState([]);

    const url = "/Users/purvinification/IdeaProjects/schuepfer_frontend/src/views/Action/data.txt"
    // Create a ref to access the file input element
    const fileInputRef = useRef(null);
    // const [selectedOption, setSelectedOption] = useState('');

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        setSelectedFile(file);

        if (file) {
            fileInputRef.current.nextSibling.innerText = file.name;
            setImageName(file.name);
        } else {
            fileInputRef.current.nextSibling.innerText = 'Select file';
            setImageName('');
        }
    };

    const resetState = () => {
        setSelectedFile(null);
        setSelectedJobId('');
        setUploadProgress(0);
        setUploadCompleted(false);
        setUploadError(false);
        fileInputRef.current.nextSibling.innerText = 'Select file';
    };
    const handleUpload = async () => {
        const formData = new FormData();
        formData.append('file', selectedFile);
        formData.append('imageName', imageName);
        formData.append('jobId', selectedJobId);

        try {
            setUploadProgress(0);
            setUploadCompleted(false);
            setUploadError(false);

            // Add a delay of 1 second before starting the upload
            setTimeout(async () => {
                try {
                    // Simulate the upload process with a delay of 10 milliseconds for each percentage increment
                    for (let percentage = 0; percentage <= 100; percentage++) {
                        await new Promise((resolve) => setTimeout(resolve, 10));
                        setUploadProgress(percentage);
                    }

                    // Upload the image to the image location URL
                    const imageResponse = await axios.post('https://reports.schuepferinc.com/dbapp/upload', formData);

                    const imageUrl = imageResponse.data.imageUrl;

                    // You can now use imageUrl, imageName, and selectedJobId as needed.
                    console.log('Image URL:', imageUrl);
                    console.log('Image Name:', imageName);
                    console.log('Job ID:', selectedJobId);

                    setUploadCompleted(true);
                    setTimeout(resetState, 3000);
                } catch (error) {
                    console.error('Error uploading image:', error);
                    setUploadError(true); // Set the error state to true
                    setUploadCompleted(false);
                    setTimeout(resetState, 3000);

                }
            }, 1000); // Delay of 1 second (1000 milliseconds)
        } catch (error) {
            console.error('Error setting upload delay:', error);
        }
    };
    useEffect(() => {
        // Make a GET request to your Flask API
        fetch('https://reports.schuepferinc.com/dbapp/static-data')
            .then((response) => response.text()) // Read the response as text
            .then((data) => {
                // Split the comma-separated string into an array
                const optionsArray = data.split(',');
                setOptions(optionsArray);
            })
            .catch((error) => {
                console.error('Error fetching options:', error);
            });
    }, []);

    return (
        <>
            <Images_header />
            <Container className="mt--7" fluid>
                <Row>
                    <Col className="order-xl-1" xl="8">
                        <Card className="bg-secondary shadow">
                            <CardHeader className="bg-white border-0">
                                <Row className="align-items-center">
                                    <Col xs="8">
                                        <h3 className="mb-0">Site Images</h3>
                                    </Col>
                                    <Col className="text-right" xs="4"></Col>
                                </Row>
                            </CardHeader>
                            <CardBody>
                                <Form>
                                    <hr className="my-4" />
                                    <h6 className="heading-small text-muted mb-4">Select Job Name</h6>
                                    <div className="pl-sm-2">
                                        <Col md="6">
                                            {/*<Input*/}
                                            {/*    id="exampleFormControlSelect1"*/}
                                            {/*    type="select"*/}
                                            {/*    onChange={(e) => setSelectedJobId(e.target.value)}*/}
                                            {/*    value={selectedJobId}*/}
                                            {/*>*/}
                                            {/*    <option value=""></option>*/}
                                            {/*    <option value="Lime Correction">Lime Correction</option>*/}
                                            {/*    <option value="Excavation">Excavation</option>*/}
                                            {/*</Input>*/}
                                            <Input
                                                id="exampleFormControlSelect1"
                                                type="select"

                                                value={selectedJobId}
                                                onChange={(e) => setSelectedJobId(e.target.value)}
                                            >
                                                <option value="">Select an option</option>
                                                {options.map((option) => (
                                                    <option key={option} value={option}>
                                                        {option}
                                                    </option>
                                                ))}
                                            </Input>
                                        </Col>
                                    </div>
                                    <hr className="my-4" />
                                    <h6 className="heading-small text-muted mb-4">Select File</h6>
                                    <Col md="6">
                                        <div className="pl-sm-2">
                                            <Col>
                                                <Card>
                                                    <input
                                                        ref={fileInputRef}
                                                        className="custom-file-input"
                                                        id="customFileLang"
                                                        type="file"
                                                        onChange={handleFileChange}
                                                    />
                                                    <label className="custom-file-label" htmlFor="customFileLang">
                                                        {selectedFile ? selectedFile.name : 'Select file'}
                                                    </label>
                                                </Card>
                                            </Col>
                                        </div>
                                    </Col>
                                    <hr className="my-4" />
                                    {uploadProgress > 0 && !uploadError && (
                                        <div className="mt-3">
                                            <Progress value={uploadProgress} animated color={uploadCompleted ? 'success' : 'primary'} />
                                        </div>
                                    )}
                                    {uploadError && (
                                        <div className="mt-3">
                                            <Progress value={uploadProgress} animated color="danger" />
                                            <div className="text-danger">Error While Upload!</div>
                                        </div>
                                    )}
                                    {!uploadProgress && !uploadCompleted && (
                                        <Button onClick={handleUpload} color="primary">
                                            Upload Image
                                        </Button>
                                    )}
                                    {uploadCompleted && <div className="text-success">Upload Completed!</div>}
                                </Form>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    );
};

export default ImageUpload;
