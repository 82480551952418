import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import axios from "axios";
import SimpleHeader from "../../components/Headers/SimpleHeader";
import { Card, Container, Row } from "reactstrap";

function App() {
    const [rows, setRows] = useState([]);
    const [pageSize, setPageSize] = useState(5);
    const token = localStorage.getItem("token");

    const fetchProducts = async () => {
        try {
            const response = await axios.get(
                "https://reports.schuepferinc.com/dbapp/form",
                {
                    headers: {
                        "x-access-token": token,
                    },
                }
            );

            if (response.data && response.data.activity) {
                // Map through the activity array and create rows with the appropriate fields
                const formattedRows = response.data.activity.map((activity, index) => ({
                    id: index, // Using the index as a unique id for each row
                    Date_Created: activity.Date_Created,
                    ID: activity.ID,
                    dirt1: activity.dirt1,
                    dirt2: activity.dirt2,
                    dirt3: activity.dirt3,
                    job: activity.job,
                    narrative: activity.narrative,
                    precip: activity.precip,
                    precipInc: activity.precipInc,
                    stab1: activity.stab1,
                    stab2: activity.stab2,
                    stab3: activity.stab3,
                    stab4: activity.stab4,
                    stone: activity.stone,
                    tempHigh: activity.tempHigh,
                    tempLow: activity.tempLow,
                    tons: activity.tons,
                    trucks: activity.trucks,
                    weatherType: activity.weatherType,
                }));

                setRows(formattedRows);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    useEffect(() => {
        fetchProducts();
    }, []);

    const columns = [
        { field: "Date_Created", headerName: "Date Created", width: 150 },
        { field: "ID", headerName: "ID", width: 70 },
        { field: "dirt1", headerName: "Dirt - Import/Export", width: 120 },
        { field: "dirt2", headerName: "Dirt Type", width: 120 },
        { field: "dirt3", headerName: "Dirt Count", width: 90 },
        { field: "job", headerName: "Job", width: 200 },
        { field: "narrative", headerName: "Narrative", width: 150 },
        { field: "precip", headerName: "Precipitation", width: 120 },
        { field: "precipInc", headerName: "Precipitation Inch", width: 120 },
        { field: "stab1", headerName: "Stabilization", width: 120 },
        { field: "stab2", headerName: "Stab %", width: 120 },
        { field: "stab3", headerName: "Stab Area", width: 90 },
        { field: "stab4", headerName: "Stab Weight", width: 90 },
        { field: "stone", headerName: "Stone", width: 120 },
        { field: "tempHigh", headerName: "Temp. High", width: 120 },
        { field: "tempLow", headerName: "Temp. Low", width: 120 },
        { field: "tons", headerName: "Tons", width: 90 },
        { field: "trucks", headerName: "Trucks", width: 150 },
        { field: "weatherType", headerName: "Weather Type", width: 150 },
    ];

    return (
        <>
            <SimpleHeader name="React Tables" parentName="Tables" />
            <Container className="mt--6" fluid>
                <Row>
                    <div className="col">
                        <Card>
                            <div className="Container">
                                <div className="Grid">
                                    <DataGrid
                                        rows={rows}
                                        columns={columns}
                                        components={{ Toolbar: GridToolbar }}
                                        checkboxSelection={true}
                                        pageSize={pageSize}
                                        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                                        rowsPerPageOptions={[5, 10, 20, 30]}
                                        pagination
                                    />
                                </div>
                            </div>
                        </Card>
                    </div>
                </Row>
            </Container>
        </>
    );
}

export default App;
