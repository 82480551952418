/*!

=========================================================
* Argon Dashboard React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import "assets/plugins/nucleo/css/nucleo.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/scss/argon-dashboard-react.scss";

import AdminLayout from "layouts/Admin.js";
import AuthLayout from "layouts/Auth.js";
import AuthenticatedComponent from "./views/Action/AuthenticatedComponent";
import Auth from "layouts/Auth.js";
import Admin from "layouts/Admin.js";

ReactDOM.render(
    <BrowserRouter>
        <Switch>
            <Route path={"/auth/login"} component={AuthLayout} />
            <Redirect
                path="/auth"
                to="/auth/login"
                render={(props) => {
                    return <AuthLayout {...props} />;
                }}
            />
            <Route
                path="/auth"
                render={(props) => {
                    return <AuthLayout {...props} />;
                }}
            />
            <AuthenticatedComponent>
                <Route
                    path="/admin"
                    render={(props) => {
                        return <AdminLayout {...props} />;
                    }}
                />
                <Redirect from="/" to="/admin" component={AdminLayout} />
            </AuthenticatedComponent>
            {/*<RoleComponent>*/}
            {/*  <Route*/}
            {/*    path="/admin/react-table"*/}
            {/*    render={(props) => {*/}
            {/*      return <AdminLayout {...props} />;*/}
            {/*    }}*/}
            {/*  />*/}
            {/*</RoleComponent>*/}
        </Switch>
    </BrowserRouter>,
  document.getElementById("root")
);
// <BrowserRouter>
//     <Switch>
//         <Route path={"/Login"} component={AuthLayout} />
//         <Route path={"/auth"} component={AuthLayout} />
//         {/*<Redirect from="/"  to="/auth/index"/>*/}
//         <AuthenticatedComponent>
//             {/*<Route path={"/admin/index"} component={Sact} />*/}
//             <Redirect from="/Login" to="/auth/login" />
//             <Redirect from="/index" to="/sact/forms" />
//             <Route path={"/sact/user-profile"} component={Sact} />
//             <Route path={"/sact/tables"} component={Sact} />
//             <Route path={"/sact/forms"} component={Sact} />
//             <Route path={"/sact/icons"} component={Sact} />
//         </AuthenticatedComponent>
//     </Switch>
// </BrowserRouter>,