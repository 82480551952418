/*!

=========================================================
* Argon Dashboard React - v1.2.2
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col
} from "reactstrap";
import axios from "axios";
import {useState} from "react";
import {Redirect, Route, useHistory} from "react-router-dom";
// import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";


// import { useNavigate } from "react-router-dom";

import redirect from "react-router-dom/es/Redirect";

const Login = () => {
  // let navigate = useNavigate();

  const history = useHistory()

  const handleSubmit = e => {
    // Prevent the default submit and page reload
    e.preventDefault()


    // Handle validations
    axios
        .post("https://reports.schuepferinc.com/dbapp/login", { name, password })
        .then((response) => {
          console.log(response)
          localStorage.removeItem("user");
          localStorage.setItem(
              "token",
              JSON.stringify(response.data.token).replace(/"/g, "")

          );
          console.log(response.data.token)
          // this.state = {auth:0,}
          // navigate("/daily-sheet");
          // redirect("/daily-sheet");
          history.push("/admin/daily-sheet")

        })

        .catch((err) => {
          // log request error and prevent access to undefined state
          // this.setState({ error: true, email: "", password: "" });
          console.error(err);
          // this.setState({auth: !this.state.auth})

        });



  }

  const [name, setname] = useState()
  const [password, setPassword] = useState()
  return (
      <>

        <Col lg="5" md="7">
          <Card className="bg-secondary shadow border-0">
            <CardHeader className="bg-transparent pb-5">
              <div className="text-muted text-center mt-2 mb-3">
                <small>Sign in with Username</small>
              </div>
            </CardHeader>
            <CardBody className="px-lg-5 py-lg-5">
              <Form action="" id="login" method="post" onSubmit={handleSubmit}>
                <FormGroup className="mb-3">
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-email-83" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                        type="text"
                        name="name"
                        id="name"
                        value={name}
                        onChange={e => setname(e.target.value)}
                        placeholder="Username"

                    />

                  </InputGroup>
                </FormGroup>
                <FormGroup>
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-lock-circle-open" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                        type="password"
                        name="password"
                        id="password"
                        value = {password}
                        onChange={e => setPassword(e.target.value)}
                        placeholder="Password"

                    />
                  </InputGroup>

                </FormGroup>
                {/*<div className="text-center">*/}
                {/*  <Button className="my-4" color="primary" type="button">*/}
                {/*    <input*/}
                {/*        type="submit"*/}
                {/*           value="Sign in" />*/}
                {/*  </Button>*/}
                {/*</div>*/}
                <div className="text-center">
                  <Button className="my-4" color="primary" type="submit">
                    Sign in
                  </Button>
                </div>
              </Form>
            {/*</form>*/}
            </CardBody>
          </Card>
        </Col>

      </>

  );

};

export default Login;
