import React, { useState, useEffect } from 'react';
import Images_header from "../../components/Headers/Images_header";
import { Button, Card, CardBody, CardHeader, Col, Container, Form, Input, Row } from "reactstrap";
import axios from "axios";
import './styles.css'; // Replace with the correct path to your CSS file

const Profile = () => {
    const token = localStorage.getItem("token");
    const [images, setImages] = useState([]); // State variable to store images

    const handleSubmit = (e) => {
        e.preventDefault();
        axios
            .get("https://reports.schuepferinc.com/dbapp/get_images")

            .then((response) => {
                console.log(response);
                if (response.data.images) {
                    console.log(response.data.images); // Log the images data
                    setImages(response.data.images); // Update the state with fetched images
                    console.log(images); // Log the images state after updating
                }
            })
            .catch((err) => {
                console.error(err);
            });
    };

    useEffect(() => {
        // This effect will run once, when the component mounts.
        // You can use it to load initial data if needed.
        handleSubmit({ preventDefault: () => {} });
    }, []); // Empty dependency array means it only runs once on mount

    // Create an object to group images by ID
    const imagesGroupedByID = images.reduce((grouped, image) => {
        const { job_id } = image;
        if (!grouped[job_id]) {
            grouped[job_id] = [];
        }
        grouped[job_id].push(image);
        return grouped;
    }, {});

    const uniqueIDs = Object.keys(imagesGroupedByID);

    return (
        <>
            <Images_header />
            <Container className="mt--7" fluid>
                <Row>
                    <Col className="order-xl-2 mb-5 mb-xl-0" xl="4"></Col>
                    <Col className="order-xl-1" xl="8">
                        <Card className="bg-secondary shadow">
                            <CardHeader className="bg-white border-0">
                                <Row className="align-items-center">
                                    <Col xs="8">
                                        <h3 className="mb-0">Site Images</h3>
                                    </Col>
                                    <Col className="text-right" xs="4"></Col>
                                </Row>
                            </CardHeader>
                            <CardBody>
                                <div className="d-flex"> {/* Create a flex container */}
                                    <div className="image-list"> {/* Left side with images */}
                                        <ul className="image-grid">
                                            {uniqueIDs.map((id) => (
                                                <li key={id} className="image-group"> {/* Apply the image-group class */}
                                                    <h4>JOB : {id}</h4>
                                                    <ul className="image-subgrid">
                                                        {imagesGroupedByID[id].map((image, index) => (
                                                            <li key={index} className="image-item">
                                                                <a href={image.image_url} target="_blank" rel="noopener noreferrer">
                                                                    {/* Add the anchor tag around the image */}
                                                                    <img src={image.image_url} alt={`Image ${image.job_id}`} />
                                                                </a>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                    <div className="vertical-divider"></div> {/* Vertical divider */}
                                    <div className="image-details"> {/* Right side with details */}
                                        {/* Display details here */}
                                    </div>
                                </div>

                                {images.length === 0 && <p>No images available.</p>}
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    );
};


export default Profile;
